/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, ContactForm, GCalendar } from '@swp/components'
import SiteHeader from '../../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Apartmán k pronájmu v Bulharsku"}>
        <SiteHeader set="ly5dmp6gibc" currentLanguage={1} />

        <Column className="css-1bp6mum pb--20 pt--12" name={"uvod-0"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s8 --center el--1 flex--center" anim={"2"} animS={"8"} columns={"1"}>
            
            <ColumnWrapper className="pb--40 pt--40" style={{"maxWidth":780}}>
              
              <Title className="title-box fs--48 lh--1 mt--10" content={"<br>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--style7 subtitle-box--shadow4 fs--43 pb--06" content={"<font color=\"#000009\">Here you can find the enquiry form\nand approximate availability of dates</font>"}>
              </Subtitle>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--40" name={"rezervace"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Rezervation"}>
              </Subtitle>

              <Text className="text-box mb--40" style={{"maxWidth":686}} content={"Booking dates in the calendar are approximate, please fill in the form below, we will be happy to provide you with up-to-date information."}>
              </Text>

              <ContactForm className="--shape2 --style1 ff--2 fs--16 ls--0 lh--14" style={{"paddingBottom":0}} action={"/contact"} fields={[{"name":"Your name","type":"text","required":true,"placeholder":"Set yor name","id":"wtxo63riglkr"},{"name":"E-mail","type":"email","required":true,"placeholder":"","id":"kd9tcec8lmzh"},{"name":"Phone number","type":"text","placeholder":"Phone number","id":"vm9wllnh3j9h"},{"name":"Message","type":"textarea","required":true,"placeholder":"Write your text","id":"j7j552udlw3w"},{"name":"Sent","type":"submit","id":"ig2vq07dykyx"},{"id":"hokh1clszbj2","type":"textarea","name":"Personal data are processed in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation)."}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1xr2863 pb--60 pt--50" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/e00e3d4499d746c69f5035603ec9c00e_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/e00e3d4499d746c69f5035603ec9c00e_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/e00e3d4499d746c69f5035603ec9c00e_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/e00e3d4499d746c69f5035603ec9c00e_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/e00e3d4499d746c69f5035603ec9c00e_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/e00e3d4499d746c69f5035603ec9c00e_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/e00e3d4499d746c69f5035603ec9c00e_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/e00e3d4499d746c69f5035603ec9c00e_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s8" anim={"2"} animS={"8"}>
              
              <Title className="title-box mb--25" content={"Availability"}>
              </Title>

              <GCalendar src={"https://calendar.google.com/calendar/embed?src=krozboud%40gmail.com&ctz=UTC&showTitle=0"} style={{"paddingRight":0,"paddingBottom":12}}>
              </GCalendar>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<font color=\"#f4f5f9\">ADRESS OBZOR</font><font color=\"#f4f5f9\"><br></font>"}>
              </Subtitle>

              <Text className="text-box" content={"Rusalka Apart-Residence\n<br>Ул. “ Отец Паисий” 10, Ап 2ь\n<br>8250 Obzor, Bulharsko"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Contact</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"+420 603 524 941<br>krozboud@gmail.com<br>Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}